
html {
  font-size: 14px;
  line-height: 1.4;
}

body {
  font-size: 1em;
  // font-family: 'Roboto', sans-serif;
  font-weight: 300;
  color: #000000;
}

h1 {
  font-size: 2.5em;
  font-weight: 700;
  text-transform: capitalize;
}

h2 {
  font-size: 2em;
  font-weight: 700;
  text-transform: capitalize;
}
h3 {
  font-size: 1.5em;
  font-weight: 300;
  text-transform: capitalize;
}

a {
  color: #0082AB;
}

b, strong {
  font-weight: 600;
}

.event-logo-homepage {
    position:relative;
    padding: 0;
    margin-bottom: 20px;
    margin-top: 10px;

}

/* set a max-width for horizontal fluid layout and make it centered */
.container-fluid {
  max-width: 1600px; /* or 950px */
}

/* Navigation */

.nav-item {
  font-family: 'Roboto', sans-serif;
  color: #0082AB;
}

.nav-link {
  color: #0082AB;
}

.navbar-brand {
  font-family: 'Roboto', sans-serif;
  text-transform: lowercase;
}

.global-navigation {
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  color: #0082AB;
  font-size: .9em;
  text-transform: uppercase;
}

.navbar>li>a{
  color: red;
}

.event-navigation {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
  font-size: 9pt;
  // color: #000000;
  color: green;
  text-transform: uppercase;

}

.event-navigation-icon {
  padding-left: 5px;
  padding-right: 5px;
  color: #0082AB;
}

.navbar-light {
  background-color:transparent !important;
  border-color:transparent;
  background-image:none;
  box-shadow:none;
}

/* Left navigation */
/* TODO: all of these left-nav elements can probably be collapsed */
.left-nav-navs {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 9pt;
  text-transform: uppercase;
  color: #000000;
}

.left-nav-months {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 9pt;
  text-transform: uppercase;
  color: #000000;
}
/* set the current month style */
.left-nav-months.current {
  font-weight: 900;
  color: #0082AB;
}

.left-nav-year {
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
  font-size: 18pt;
  color: #000000;
}
.left-nav-event {
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
  font-size: 9pt;
  text-transform: capitalize;
}

/* Footer */
.footer-nav {
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  font-size: 1.3em;
  text-transform: uppercase;
  color: #0082AB;
  margin-bottom: 1.5rem;
}

.footer-heading {
  font-family: 'Roboto', sans-serif;
  text-transform: capitalize;
  color: #0082AB;
  font-size: 1.3em;
}

h1.footer-heading {
  font-size: 1.2em;
  font-weight: 400;
}

h2.footer-heading {
  font-size: 1.1em;
  font-weight: 300;
}

.footer-content {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: .9em;
  color: #000;
}
a.footer-content {
  font-weight: 300;
  color: #0082AB;
}

.footer-nav-background {
  background-color: #f7f7f7;
  padding-top: 20px;
  border-top: 2px solid #0082AB;
  // background-color: desaturate( #0082AB, 80% );
  padding-left:40px;
  padding-right:40px;
}

 .footer-nav-col {
   padding-bottom: 30px;
 }
 //
 // .footer-nav-col:first-child {
 //  //  margin-left: 50 !important;
 //   border-top-width:thick;
 //   border-top-color:red;
 // }
 //
 // .footer-nav-col:last-child {
 //   margin-right: 0 !important;
 // }

.footer-nav-row {
  margin-top:20px;
}

/* Sponsors */

.sponsor-image {

}

.sponsor-row {
  display: flex;
  align-items: center;
}

.sponsor-cta {
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  color: #000000;
}

.cta-row {
  background-color: lighten(#bfbfc1, 20%);
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
}

a.sponsor-cta {
  color: #0082AB;
  // color: #fff;
}

/* Homepage */

.homepage-grid-col {
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
}
.homepage-grid-date {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 9pt;
  text-transform: uppercase;
  color: #000000;
  display: inline-block;
  width: 100%;
  margin: auto;
  text-align: center;
}

.homepage-grid-city {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 2em;
  text-transform: capitalize;;
  color: #000000;
  display: inline-block;
  width: 100%;
  margin: auto;
  text-align: center;

}

.homepage-grid-city a {
  color: #000000;
}

.homepage-grid-desc {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 9pt;
  color: #000000;
}

/* Program */
button.accordion {
    background-color: #eee;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: 0.4s;
}

button.accordion.active, button.accordion:hover {
    background-color: #ddd;
}

div.panel {
    padding: 0 18px;
    background-color: white;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
}

// old googlemaps.css content
.labels {
  color: black;
  background-color: white;
  font-family: "Lucida Grande", "Arial", sans-serif;
  font-size: 8px;
  font-weight: bold;
  text-align: center;

  border: 1px solid gray;
  white-space: nowrap;
}

// from bootstrap-sortable
table.sortable span.sign {
    display: block;
    position: absolute;
    top: 50%;
    right: 5px;
    font-size: 12px;
    margin-top: -10px;
    color: #bfbfc1;
}

table.sortable th:after {
    display: block;
    position: absolute;
    top: 50%;
    right: 5px;
    font-size: 12px;
    margin-top: -10px;
    color: #bfbfc1;
}

table.sortable th.arrow:after {
    content: '';
}

table.sortable span.arrow, span.reversed, th.arrow.down:after, th.reversedarrow.down:after, th.arrow.up:after, th.reversedarrow.up:after {
    border-style: solid;
    border-width: 5px;
    font-size: 0;
    border-color: #ccc transparent transparent transparent;
    line-height: 0;
    height: 0;
    width: 0;
    margin-top: -2px;
}

    table.sortable span.arrow.up, th.arrow.up:after {
        border-color: transparent transparent #ccc transparent;
        margin-top: -7px;
    }

table.sortable span.reversed, th.reversedarrow.down:after {
    border-color: transparent transparent #ccc transparent;
    margin-top: -7px;
}

    table.sortable span.reversed.up, th.reversedarrow.up:after {
        border-color: #ccc transparent transparent transparent;
        margin-top: -2px;
    }

table.sortable span.az:before, th.az.down:after {
    content: "a .. z";
}

table.sortable span.az.up:before, th.az.up:after {
    content: "z .. a";
}

table.sortable th.az.nosort:after, th.AZ.nosort:after, th._19.nosort:after, th.month.nosort:after {
    content: "..";
}

table.sortable span.AZ:before, th.AZ.down:after {
    content: "A .. Z";
}

table.sortable span.AZ.up:before, th.AZ.up:after {
    content: "Z .. A";
}

table.sortable span._19:before, th._19.down:after {
    content: "1 .. 9";
}

table.sortable span._19.up:before, th._19.up:after {
    content: "9 .. 1";
}

table.sortable span.month:before, th.month.down:after {
    content: "jan .. dec";
}

table.sortable span.month.up:before, th.month.up:after {
    content: "dec .. jan";
}

table.sortable>thead th:not([data-defaultsort=disabled]) {
    cursor: pointer;
    position: relative;
    top: 0;
    left: 0;
}

table.sortable>thead th:hover:not([data-defaultsort=disabled]) {
    background: #efefef;
}

table.sortable>thead th div.mozilla {
    position: relative;
}

// speaker page
.speaker-page {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  color: #000000;
}
h2.speaker-page {
  font-size: 2em;
  font-weight: 700;
  text-transform: capitalize;
}
h3.speaker-page {
  font-size: 1.5em;
  font-weight: 300;
  text-transform: capitalize;
}

.speaker-bio-talks {
  padding-top: 15px;
}

// speakers page
.speakers-page {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  color: #000000;
}
h2.speakers-page {
  font-size: 2em;
  font-weight: 700;
  text-transform: capitalize;
}
h3.speakers-page {
  font-size: 1.5em;
  font-weight: 300;
  text-transform: capitalize;
}
img.speakers-page {
  margin-top: 20px;
  margin-bottom: 20px;
}

// talk page

.talk-page {
font-family: 'Roboto', sans-serif;
font-weight: 300;
color: #000000;
}

h2.talk-page {
  font-size: 2em;
  font-weight: 700;
  text-transform: capitalize;
}
h3.talk-page {
  font-size: 1.5em;
  font-weight: 300;
  text-transform: capitalize;
}

h1.welcome-page {
  color: #FFF;
  // text-shadow: 1px 1px 2px #000000;
  // text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.welcome-page-date {
  text-transform: uppercase;
}

// Note that welcome-page-masthead is also defined in templates/welcome.html so we cannot use SCSS here
.welcome-page-masthead {
  background: #bfbfc1; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(#0082AB, #bfbfc1); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(#0082AB, #bfbfc1); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(#0082AB, #bfbfc1); /* For Firefox 3.6 to 15 */
  background: linear-gradient(#0082AB, #bfbfc1); /* Standard syntax */
  padding: 20px;
  margin-bottom:20px;
  margin-top:20px;
}

a.welcome-page-masthead-link {
  color: white;
}

a:hover.welcome-page-masthead-link {
  color: #bfbfc1;
  border-color: #bfbfc1;
  text-decoration: none;
}

.welecome-page-masthead-venue {
  font-size: 1.2em;

}

.welcome-page-cta {
  margin-left: 5px;
  margin-right: 5px;
  padding-left: 0px;
  padding-right: 0px;
}

.welcome-page-cta:first-child {
  margin-left: 15px;
  margin-right: 5px;
}

@media only screen and (min-width: 768px) {

  .welcome-page-cta:first-child {
    margin-left: 0px;
    margin-right: 5px;
  }

}

.welcome-page-event-logo {
  max-width: 50%;
}


.organizer-card {
  padding-bottom: 20px;
}

.event-page-nav-home{
  padding-left: .9rem;

}

@media only screen and (min-width: 1024px) {

  .event-page-nav-home {
    padding-left: 1.25rem;

  }

}

.events-page-col {
  padding: 30px;
}

.events-page-months {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 14pt;
  text-transform: uppercase;
  color: #000000;
}

.events-page-event {
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
  font-size: 11pt;
  text-transform: capitalize;
}

.event-cta-button:first-child {
  margin-left: 0px;
}

// .event-cta-button:last-child {
//   margin-left: 5px;
// }
.event-cta-button {
  margin-left: 5px;
}


// Program page items

.program-page {

}
h3.program-page {
  margin-top:8px;
}

.program-page-desc {
  font-size: .85em;
}


.program-element {
  overflow: hidden;
  height: 1%

}

@media only screen and (min-width: 768px) {

  .program-element {
    border-radius: 5px;
    margin-right: 5px;
    overflow: hidden;
    height: 1%

  }
}

.program-custom {
  background-color: lighten( $devopsdays-gray, 15% );
  color: #000;
}

.program-talk {
  background-color: lighten( $devopsdays-blue, 50% );
  color: #000;
}

.program-talk>a{
  color: #000;
}

.program-ignite {
  background-color: lighten(#00C342, 50%);
  color: #000;
}

.program-ignite>ul>li{
  color: #000;
}

.program-ignite>ul {
  margin-bottom: 0px;
}

.program-ignite>ul>li>a{
  color: #000;
}

.program-open-space {
  background-color: lighten( #FF8300, 20%);
  color: #000;
}

.program-open-space>a{
  color: #000;
}

.program-workshop {
  background-color: #FFFA99;
  color: #000
}

.program-workshop>a{
  color: #000;
}

.program-row {
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

// legacy styles

.box {
  padding:1.5em;
  margin-bottom:1.5em;
  background:#E5ECF9;
}

//formatting for content provided by events, pages, etc
.content-text h1, h2, h3 {
  text-transform: none;
}

// 404 page

.page-404-header {
  color: $devopsdays-blue;
  font-size: 2.5em;
  font-weight: 700;
  text-transform: capitalize;
}

.page-404-content {
  color: darken($devopsdays-gray, 50%);
  font-size: 1.5em;
  font-weight: 300;
}
